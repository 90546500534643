import React from "react";
import { graphql, Link, useStaticQuery } from "gatsby";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { GatsbySeo } from "gatsby-plugin-next-seo";
import { Helmet } from "react-helmet";
import EventItem from "../components/eventItem";
import Layout from "../components/layout";
import { Nav } from "react-bootstrap";
import { checkPropertiesForNull } from "../utils/objectHelper";
import { SafeHtmlParser } from "../components/safeHtmlParser";
import uuid4 from "uuid4";

const WhatWeDoPage = () => {
	const data = useStaticQuery(
		graphql`
			query {
				site {
					siteMetadata {
						siteUrl
					}
				}
				pageData: wpPage(slug: { eq: "what-we-do" }) {
					seoFields {
						title
						metaDescription
						opengraphTitle
						opengraphDescription
						localBusinessSchema
						image {
							node {
								sourceUrl
								altText
								localFile {
									publicURL
									childImageSharp {
										gatsbyImageData(
											formats: [AUTO, WEBP]
											quality: 100
											transformOptions: { cropFocus: CENTER, fit: CONTAIN }
											layout: CONSTRAINED
											placeholder: BLURRED
										)
										original {
											width
											height
										}
									}
								}
							}
						}
					}
					whatWeDoFieldGroups {
						servicesSection {
							heading
							description
							tags {
								title
								description
								tagHeading {
									title
									target
									url
								}
								tagList {
									nodes {
										... on WpEvent {
											title
											eventInfo {
												section
												when
												where
												order
												info
												from
												contactNumber
												calendarButton
												buttonText
												buttonLink
												eventImage {
													node {
														altText
														localFile {
															childImageSharp {
																gatsbyImageData(
																	formats: [AUTO, WEBP]
																	quality: 100
																	transformOptions: {
																		cropFocus: CENTER
																		fit: CONTAIN
																	}
																	layout: CONSTRAINED
																	placeholder: BLURRED
																)
																original {
																	width
																	height
																}
															}
														}
													}
												}
											}
										}
									}
								}
							}
						}
					}
				}
			}
		`
	);

	const {
		pageData: {
			seoFields,
			whatWeDoFieldGroups: { servicesSection },
		},
	} = data;

	const siteUrl = data.site.siteMetadata.siteUrl;

	const breadcrumb = {
		"@context": "http://schema.org",
		"@type": "BreadcrumbList",
		itemListElement: [
			{
				"@type": "ListItem",
				position: 1,
				name: "Home",
				item: {
					url: `${siteUrl}`,
					id: `${siteUrl}`,
				},
			},
			{
				"@type": "ListItem",
				position: 2,
				name: "What we do",
				item: {
					url: `${siteUrl}/what-we-do`,
					id: `${siteUrl}/what-we-do`,
				},
			},
		],
	};

	return (
		<Layout>
			<Helmet>
				{" "}
				<script type="application/ld+json">{JSON.stringify(breadcrumb)}</script>
			</Helmet>
			<GatsbySeo
				title={seoFields?.title}
				description={seoFields?.metaDescription}
				language="en"
				openGraph={{
					type: "website",
					url: `${siteUrl}/what-we-do`,
					title: `${seoFields?.opengraphTitle}`,
					description: `${seoFields?.opengraphDescription}`,
					images: [
						{
							url: `${seoFields?.image?.node.sourceUrl}`,
							width: `${seoFields?.image?.node.localFile.childImageSharp.original.width}`,
							height: `${seoFields?.image?.node.localFile.childImageSharp.original.height}`,
							alt: "What we do at Holding Space",
						},
					],
				}}
			/>

			{servicesSection &&
				!checkPropertiesForNull(servicesSection, ["heading"]) && (
					<section id="intro" className="py-5">
						<Container>
							<Row>
								<Col>
									<h1 className="text-primary">{servicesSection?.heading}</h1>
								</Col>
							</Row>
							<Row>
								<Col>
									<SafeHtmlParser htmlContent={servicesSection?.description} />
								</Col>
							</Row>
							{servicesSection.tags && servicesSection.tags.length > 0 && (
								<Row className="text-center d-none d-xl-block">
									<Col className="w-100 text-center">
										<Nav className="ml-auto mr-auto">
											{servicesSection.tags.map((item) => (
												<>
													{item?.tagHeading && (
														<Nav.Item key={uuid4()}>
															<Nav.Link
																as={Link}
																to={`#${item?.tagHeading?.url}`}
															>
																<SafeHtmlParser
																	htmlContent={item.tagHeading?.title}
																/>
															</Nav.Link>
														</Nav.Item>
													)}
												</>
											))}
										</Nav>
									</Col>
								</Row>
							)}
						</Container>
					</section>
				)}

			{servicesSection.tags.map((item) => (
				<>
					{item.tagList && item.tagList.nodes.length > 0 && (
						<section id={item?.tagHeading?.url} className="py-3" key={uuid4()}>
							<Container>
								<Row>
									<Col>
										<h2 className="text-primary">{item?.title}</h2>
									</Col>
								</Row>
								{item?.description && (
									<Row>
										<Col>
											<SafeHtmlParser htmlContent={item?.description} />
										</Col>
									</Row>
								)}

								{item.tagList?.nodes.map((event) => (
									<>
										<EventItem
											name={event?.title}
											when={event?.eventInfo.when}
											where={event?.eventInfo.where}
											btnText={event?.eventInfo.buttonText}
											link={event?.eventInfo.buttonLink}
											eventImage={
												event?.eventInfo?.eventImage.node?.localFile
													?.childImageSharp.gatsbyImageData
											}
											info={event?.eventInfo.info}
											contact={event?.eventInfo.contactNumber}
											from={event?.eventInfo.from}
											calendar={event?.eventInfo.calendarButton}
										/>
									</>
								))}

								<Row>
									<Col className="text-right">
										<Link to="#intro">Back to Top</Link>
									</Col>
								</Row>
							</Container>
						</section>
					)}
				</>
			))}
		</Layout>
	);
};
export default WhatWeDoPage;
