import React from "react";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import { GatsbyImage } from "gatsby-plugin-image";

const EventItem = ({
	name,
	when,
	where,
	btnText,
	link,
	link2,
	eventImage,
	color,
	info,
	contact,
	from,
	button2,
	calendar,
}) => {
	const textColor = color || "text-black";

	return (
		<Row className="my-5 align-items-center">
			<Col xs={12} md={5} lg={4}>
				<GatsbyImage image={eventImage} className="h-100" />
			</Col>
			<Col xs={12} md={7} lg={5} className="ml-lg-5">
				<Row>
					<Col>
						<h4 className={`${textColor} mb-4`}>{name}</h4>
						{info !== null && (
							<div
								dangerouslySetInnerHTML={{ __html: info }}
								className={`${textColor} ${
									info ? "d-block" : "d-none"
								} event-info`}
							></div>
						)}

						<p className={`${textColor} ${from ? "d-block" : "d-none"}`}>
							<span className="font-weight-bold">From:{` `}</span>
							{from}
						</p>
						<p className={`${textColor} ${when ? "d-block" : "d-none"}`}>
							<span className="font-weight-bold">When:{` `}</span>
							{when}
						</p>
						<p className={`${textColor} ${where ? "d-block" : "d-none"}`}>
							<span className="font-weight-bold">Where:{` `}</span>
							{where}
						</p>
						<p className={`${textColor} ${contact ? "d-block" : "d-none"}`}>
							<span className="font-weight-bold ">Contact:{` `}</span>
							<a href={`tel:${contact}`}>{contact}</a>
						</p>
						<Row>
							<Col md={6}>
								<Button
									variant="info"
									href={link}
									size="lg"
									className="mt-3 full-width-button"
								>
									{btnText}
								</Button>
							</Col>
							<Col className={`${calendar ? "d-block" : "d-none"}`}>
								<Button
									variant="info"
									href="/events-calendar"
									size="lg"
									className="mt-3 full-width-button border-0 bg-primary"
								>
									View Calendar
								</Button>
							</Col>
							<Col className={`${button2 ? "d-block" : "d-none"}`}>
								<Button
									variant="info"
									href={link2}
									size="lg"
									className="mt-3 full-width-button border-0 bg-primary"
								>
									Find Out More
								</Button>
							</Col>
						</Row>
					</Col>
				</Row>
			</Col>
		</Row>
	);
};

export default EventItem;
